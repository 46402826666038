$green: #87DAC9;
$black: #170710;
$grey: #4F4E4F;
$pale: #F4F2F3;
$white: #ffffff;
$yellow: #F7C745;
$m-breakpoint: 500px;
$l-breakpoint: 800px;

*{
    font-family: proxima-nova, sans-serif; 
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

h1, h2, h3, h4, h5{
    font-weight: 900;
    color: $black;
}

p{
    line-height: 1.5;
    color: $black;
    font-size: 1.1rem;
    margin-bottom: 16px;
}

.container{
    max-width: 1000px;
    margin: 0 auto;
}

#site-header__menu-btn:checked ~ .site-header__menu-nav {
    display: block;
    position: absolute;
    width: 100%;
    background: black;
    left: 0;
    margin-top: 15px;
    .site-header__menu-link{
        color: $green;
        display: block;
        margin-right: 0;
        padding: 15px 30px;
    }
}


.site-header{
    background: $green;
    padding: 20px;
    .site-header__inner{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .site-header__masthead{
        display: block;
        img{
            display: block;
        }
        &:focus{
            background: $yellow;
            outline: 3px solid $yellow;
        }
    }
    .site-header__menu{
        .site-header__menu-nav {
            display: none;
            @media screen and (min-width: $l-breakpoint){
                display: block;
                position: static !important;
                width: auto !important;
                background: transparent !important;
                left: 0;
                margin-top: 0 !important;
            }
        }
        input {
            display: none;
        }
        .site-header__menu-btn {
            font-size: 1.7rem;
            color: $black;
            font-weight: 900;
            cursor: pointer;
            display: block;

            &:hover {
                text-decoration: underline;
            }
            .site-header__menu-btn__hamburger {
                display: inline-block;
                vertical-align: middle;
                div {
                    width: 15px;
                    height: 3px;
                    background: $black;
                    margin-bottom: 3px;
                }
            }
            @media screen and (min-width: $l-breakpoint) {
                display: none;
            }
        }
    }
    .site-header__menu-link{
        font-size: 1.7rem;
        font-weight: 900;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
        &:focus{
            background: $yellow;
            outline: 3px solid $yellow;
        }

        @media screen and (min-width: $l-breakpoint){
            color: $black !important;
            margin-right: 15px !important;
            display: inline !important;
            padding: 0 !important;
            &:last-of-type{
                margin-right: 0px !important;
            }
        }
    }
}

.site-footer{
    background: $black;
    color: $white;
    text-align: center;
    padding: 70px 20px;
    font-size: 1.1rem;
    .site-footer__link{
        font-weight: bold;
        text-decoration: none;
        color: $white;
        &:hover{
            text-decoration: underline;
        }
        &:focus{
            color: $black;
            background: $yellow;
            outline: 3px solid $yellow;
        }
    }
}

.hero{
    background: $green;
    padding: 20px 20px 40px 20px;
    @media screen and (min-width: $l-breakpoint) {
        padding: 50px 20px 70px 20px;
    }
    .hero__headline{
        font-size: 2.8rem;
        line-height: 1.1;
        @media screen and (min-width: $m-breakpoint) {
            font-size: 4rem;
            max-width: 66.67%;
        }
        @media screen and (min-width: $l-breakpoint) {
            font-size: 5rem;
        }
    }
    .hero__divider{
        border: none;
        border-bottom: 5px solid $black;
        margin: 40px 0px;
        max-width: 150px;
    }
    p{
        @media screen and (min-width: $l-breakpoint) {
            font-size: 1.2rem;
            max-width: 66.67%;
        }   
    }
}

.button{
    text-align: center;
    display: block;
    font-weight: bold;
    color: $black;
    font-size: 1.2rem;
    text-decoration: none;
    padding: 15px;
    width: 100%;
    border: 5px solid $black;
    margin-bottom: 15px;
    &:focus{
        outline: 5px solid $yellow;
    }
    @media screen and (min-width: $l-breakpoint) {
        width: initial;
        display: inline-block;
        margin-right: 10px;
        padding: 15px 25px;
    }
    &.button--primary{
        background: $black;
        color: $white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        &:after{
            content: "";
            background-image: url("/assets/forward-white.svg");
            display: inline-block;
            width: 18px;
            height: 26px;
            margin-left: 15px;
        }
        @media screen and (min-width: $l-breakpoint) {
            display: inline-flex;
        }
    }
}

.actions{
    margin-top: 40px;
    .actions__button{
        text-align: center;
        display: block;
        font-weight: bold;
        color: $black;
        font-size: 1.2rem;
        text-decoration: none;
        padding: 15px;
        width: 100%;
        border: 5px solid $black;
        margin-bottom: 15px;
        &:focus{
            outline: 5px solid $yellow;
        }
        &:hover {
            opacity: 0.7;
        }
        @media screen and (min-width: $l-breakpoint) {
            width: initial;
            display: inline-block;
            margin-right: 10px;
            padding: 15px 25px;
        }
    }
    .actions__button--primary{
        background: $black;
        color: $green;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        &:after{
            content: "";
            background-image: url("/assets/forward.svg");
            display: inline-block;
            width: 18px;
            height: 26px;
            margin-left: 15px;
        }
        @media screen and (min-width: $l-breakpoint) {
            display: inline-flex;
        }
    }
}

.partnerships{
    padding: 40px 20px;
    padding-top: 0;
    background: $pale;
    text-align: center;
    @media screen and (min-width: $m-breakpoint) {
        padding: 70px 20px;
        padding-top: 20px;
    }
    .partnerships__logos{
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-bottom: -25px;
        margin-top: 20px;
    }
    .partnerships__link{
        width: calc(50% - 17.5px);
        display: inline-block;
        margin-right: 15px;
        margin-bottom: 10px;
        &:nth-of-type(even){
            margin-right: 0px;
        }
        &:hover {
            opacity: 0.7;
        }
        &:focus {
            outline: 3px solid $yellow;
        }
        @media screen and (min-width: $m-breakpoint) {
            width: calc(33.33% - 11.25px);
            margin-right: 15px;
            &:nth-of-type(even){
                margin-right: 15px;
            }
            &:nth-of-type(3n){
                margin-right: 0px;
            }
        }
        @media screen and (min-width: $l-breakpoint) {
            width: calc(25% - 11.25px);
            margin-right: 15px;
            &:nth-of-type(even){
                margin-right: 15px;
            }
            &:nth-of-type(3n){
                margin-right: 15px;
            }
            &:nth-of-type(4n){
                margin-right: 0px;
            }
        }
    }
    .partnerships__logo{
        width: 100%;
        height: auto;
    }
    .partnerships__tagline{
        margin-right: 15px;
        margin-bottom: 10px;
        margin-top: 50px;

        span {
            display: block;
            margin-top: 25px;
            text-align: right;
        }
    }
}

.main-content{
    padding: 40px 20px;
    @media screen and (min-width: $l-breakpoint) {
        padding: 70px 20px;
    }
}

.promos{
    margin-bottom: 100px;
    .promos__title{
        font-size: 2rem;
        @media screen and (min-width: $l-breakpoint) {
            font-size: 2.5rem;
        }
    }
    &:last-of-type {
        margin-bottom: 50px;
    }
}
.promos__desc {
    color: $grey;
    margin-bottom: 0px;
    @media screen and (min-width: $l-breakpoint) {
        font-size: 1.2rem;
    }
}

.promo-list{
    margin-top: 20px;
    list-style: none;
    @media screen and (min-width: $m-breakpoint) {
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        flex-wrap: wrap;
    }
}

.promo{
    margin-bottom: 25px;
    &:hover{
        .promo__title{
            text-decoration: underline;
        }
    }
    @media screen and (min-width: $m-breakpoint) {
        width: calc(50% - 10px);
        margin-right: 20px;
        &:nth-of-type(even){
            margin-right: 0px;
        }
    }
    @media screen and (min-width: $l-breakpoint) {
        width: calc(33.33% - 26.66px);
        margin-right: 40px;
        &:nth-of-type(even){
            margin-right: 40px;
        }
        &:nth-of-type(3n){
            margin-right: 0px;
        }
    }
    .promo__link{
        display: block;
        text-decoration: none;
        border-top: 5px solid $black;
        padding: 10px 0px;
        // &:hover {
        //     border-top: 5px solid $green;
        // }
        &:focus{
            background: $yellow;
            outline: none;
        }
    }
    .promo__title{
        font-size: 1.2rem;
        margin-bottom: 10px;
        @media screen and (min-width: $l-breakpoint) {
            font-size: 1.4rem;
        }
    }
    .promo__caption{
        color: $grey;
        margin-bottom: 0px;
        @media screen and (min-width: $l-breakpoint) {
            font-size: 1.2rem;
        }
    }
}

.page-header{
    margin-bottom: 40px;
    .page-header__caption{
        text-transform: capitalize;
        // font-weight: 900;
        // color: $grey;
        font-size: 1.5rem;
        margin-bottom: 5px;
        @media screen and (min-width: $l-breakpoint) {
            max-width: 66.67%;
        }
    }
    .page-header__headline{
        font-size: 2.8rem;
        line-height: 1.1;
        @media screen and (min-width: $m-breakpoint) {
            font-size: 3.5rem;
            max-width: 66.67%;
        }
        @media screen and (min-width: $l-breakpoint) {
            font-size: 4rem;
            max-width: 66.67%;
        }
    }
}

.page-content{
    h2, h3, h4, h5{
        margin-top: 40px;
        margin-bottom: 20px;
    }
    ol, ul{
        margin-left: 20px;
    }
    li{
        margin-bottom: 10px;
        padding-left: 10px;
    }
    
    @media screen and (min-width: $m-breakpoint) {
        p, li{
            font-size: 1.2rem;
        }
    }
    @media screen and (min-width: $l-breakpoint) {
        max-width: 66.67%;
        p{
            margin-bottom: 30px;
        }
    }
    pre, figure, image{
        margin-bottom: 16px;
    }
    img{
        max-width: 100%;
        height: auto;
    }
    a{
        color: darken($green, 25);
        text-decoration: none;
        position: relative;
        &:hover{
            border-bottom: 2px solid darken($green, 25);
        }
        &:focus{
            background: $yellow;
        }
    }
    blockquote{
        padding-left: 25px;
        border-left: 5px solid $green;
    }
}

.call-to-action{
    margin-top: 40px;
    background: $pale;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    margin-bottom: -40px;
    padding: 60px 20px;
    @media screen and (min-width: $l-breakpoint) {
        margin-top: 70px;
        padding: 80px 20px;
        margin-bottom: -70px;
    }
    h2{
        font-size: 2rem;
        line-height: 1.1;
        @media screen and (min-width: $m-breakpoint) {
            font-size: 2.2rem;
        }
    }

    p{
        margin: 20px 0px 30px 0px;
        font-size: 1.2rem;
    }
}
